<template>
	<div class="text-[16px] sm:text-base bg-neutral-10">
		<div class="w-full px-4 h-12 sm:h-16 justify-center flex flex-col">
			<div class="text-wemotooBlue font-medium font-jakarta flex justify-between items-center w-full">
				<UIcon name="i-heroicons-magnifying-glass-solid" class="w-[20px] h-[20px]" />

				<input
					v-model="value"
					class="text-wemotooBlue w-full h-auto font-jakarta font-medium outline-none bg-transparent px-2"
					:type="inputType || 'text'"
					:placeholder="placeHolder"
					autofocus
					@input="(event) => $emit('_input', value)"
				/>
				<UIcon
					v-if="value !== undefined && value !== ''"
					name="i-heroicons-x-mark-16-solid"
					class="font-jakarta text-base text-wemotooGrey w-[20px] h-[20px]"
					@click="(value = undefined), $emit('clear')"
				/>

				<!-- a cross icon to clear input -->
				<!-- <div class="flex items-center pr-5"></div> -->
				<!-- <UIcon name="oui:cross" class="w-4 h-4" v-if="value" @click="value = ''" /> -->
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';

defineEmits(['_input', 'clear']);

const { labelProps, inputTypeProps, nameProps, placeHolderProps } = defineProps({
	labelProps: String,
	valueProps: String,
	inputTypeProps: String,
	nameProps: String,
	placeHolderProps: String,
});

// const value = ref<string>(valueProps ?? "");
const { value } = useField(() => nameProps ?? '');

const label = ref<string>(labelProps ?? '');
const placeHolder = ref<string>(placeHolderProps ?? label.value);
const inputType = ref<string>(inputTypeProps ?? 'text');
</script>

<style scoped>
::placeholder {
	color: #6a7077;
	opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
	/* Edge 12 -18 */
	color: #6a7077;
}
</style>
